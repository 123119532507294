
import {
    apiCrontabLists,
    apiCrontabDel,
    apiSrontabOperate,
} from "@/api/setting";
import { Component, Prop, Vue } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";
import LsDialog from "@/components/ls-dialog.vue";
@Component({
    components: {
        LsDialog,
        LsPagination,
    },
})
export default class Task extends Vue {
    // 分页
    pager: RequestPaging = new RequestPaging();

    // 获取列表
    getList() {
        this.pager.request({
            callback: apiCrontabLists,
        });
    }

    async onStop(row: any): Promise<void> {
        await apiSrontabOperate({
            id: row.id,
            operate: row.status == 1 ? "stop" : "start",
        });
        this.getList();
    }

    // 删除这个定时任务
    async onDel(id: any): Promise<void> {
        await apiCrontabDel({ id: id });
        this.getList();
    }

    // 新增
    goTaskAdd() {
        this.$router.push({
            path: "/setting/system_maintain/task_edit",
            query: {
                mode: PageMode["ADD"],
            },
        });
    }

    // 编辑
    goTaskEdit(id: number) {
        this.$router.push({
            path: "/setting/system_maintain/task_edit",
            query: {
                id: id as any,
                mode: PageMode["EDIT"],
            },
        });
    }

    created() {
        this.getList();
    }
}
